<template>
  <div class="user-box">
    <div class="user-info">
      <Poptip placement="right-start">
        <div style="position: relative">
          <Image
            v-if="userInfo.avatarUrl"
            class="hand"
            :src="userInfo.avatarUrl"
            width="40px"
            style="border-radius: 20px; overflow: hidden"
          />
          <Avatar
            v-else
            class="hand"
            :src="require('../assets/header_avatar.png')"
            icon="ios-person"
            style="width: 40px; height: 40px"
          />
          <XIcon
            class="attach"
            v-if="memberTitle != '普通用户'"
            iconClass="user_member"
          />
        </div>
        <template #content>
          <XUserMenu />
        </template>
      </Poptip>
      <div style="flex: 1; margin-left: 8px">
        <Text
          class="nickname-title"
          ellipsis
          :ellipsis-config="{ tooltip: true }"
        >
          {{ userInfo.nickName }}
        </Text>
        <br />
        <Text
          style="font-size: 10px; color: #c2c2c2; margin-top: 2.5px"
          ellipsis
          :ellipsis-config="{ tooltip: true }"
        >
          {{ memberTitle }}
        </Text>
      </div>
      <!-- <button @click="themeChange($event)">TC</button> -->
      <!-- <div style="margin-right: 8px" class="user-search" @click="userSyncData">
        <Icon type="ios-sync" />
      </div> -->
      <div class="user-search hand" @click="onSearchClick">
        <XIcon iconClass="search_gray" />
      </div>
    </div>
    <div class="user-tab">
      <template v-for="name in tabList" :key="name">
        <!-- <Tooltip
          v-if="name == 'clock'"
          theme="light"
          always
          :disabled="!showTimer"
        >
          <div class="clock" @click="showTimer = !showTimer">
            <XIcon
              :icon-class="selected == name ? 'clock_high' : 'clock_normal'"
              style="fontsize: 20px"
            />
          </div>
          <template #content>
            <XTimer />
          </template>
        </Tooltip> -->
        <div
          :class="'tab-item ' + (selected == name ? 'selected' : '')"
          @click="onTabClick(name)"
        >
          <XIcon
            :icon-class="selected == name ? name + '_high' : name + '_normal'"
            style="fontsize: 20px"
          />
        </div>
      </template>
    </div>
    <XSearch v-model="searchShow" />
    <Modal
      v-model="showTimer"
      draggable
      sticky
      :width="'250px'"
      scrollable
      :closable="!timeIsRunning"
      :mask="!timeIsRunning"
      title=" "
      class="timer-timer"
      :reset-drag-position="true"
      footer-hide
    >
      <XTimer @statusChange="statusChange" />
    </Modal>
  </div>
</template>

<script>
import XUserMenu from "@/components/XUserMenu";
import XTimer from "@/components/XTimer";
import XSearch from "@/components/XSearch";
import { syncData, clearData } from "@/common/syncUntil";

export default {
  inject: ["reload"],
  components: {
    XUserMenu,
    XTimer,
    XSearch,
  },
  data() {
    return {
      tabList: ["home", "calendar", "group", "clock"],
      showTimer: false,
      searchShow: false,
      timeIsRunning: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    },
    memberTitle() {
      let memberType = this.userInfo.memberType;
      switch (memberType) {
        case "NORMAL":
          return "普通用户";
        case "MONTH":
          return "月度会员";
        case "YEAR":
          return "年度会员";
        case "PERMANENT":
          return "永久会员";
        default:
          return "普通用户";
      }
    },
    selected() {
      let routeName = this.$route.name;
      if (routeName == "work") {
        return "home";
      } else {
        return routeName;
      }
    },
  },
  methods: {
    themeChange($eve) {
      const x = $eve.clientX;
      const y = $eve.clientY;
      const endRadius = Math.hypot(
        Math.max(x, innerWidth - x),
        Math.max(y, innerHeight - y)
      );

      document.documentElement.style.setProperty("--x", x + "px");
      document.documentElement.style.setProperty("--y", y + "px");
      document.documentElement.style.setProperty("--r", endRadius + "px");

      // 判断浏览器是否支持document.startViewTransition
      if (document.startViewTransition) {
        // 如果支持就使用document.startViewTransition方法
        document.startViewTransition(() => {
          document.querySelector("html").classList.toggle("dark");
        });
      } else {
        // 如果不支持，就使用最原始的方式，切换主题
        document.querySelector("html").classList.toggle("dark");
      }
    },
    async userSyncData() {
      await clearData();
      const res = await syncData(0);
      console.log(res);
      // this.reload()
    },
    onSearchClick() {
      this.searchShow = true;
    },
    statusChange(res) {
      this.timeIsRunning = res;
    },
    onTabClick(name) {
      if (this.selected == name) return;
      if (name == "home") {
        this.$router.push("/manager/work");
      } else if (name == "calendar") {
        this.$router.push("/manager/calendar");
      } else if (name == "clock") {
        this.showTimer = true;
        // this.$Message.info("努力开发中~");
      } else if (name == "group") {
        this.$router.push("/manager/group/quadrant");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.user-box {
  background-image: var(--vp-c-user-box-img);
  padding: 16px 20px 0px 20px;
  height: 110px;
  width: 100%;
  position: relative;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left top;
  .user-info {
    display: flex;
    align-items: center;
  }
  .nickname-title {
    font-size: 12px;
    color: var(--nickname);
  }
  .user-search {
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border-bottom-left-radius: 0;
    background-color: #d6d6d66b;
    &:hover {
      transform: scale(1.3);
    }
  }
  .user-tab {
    display: flex;
    justify-content: space-around;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .clock {
      width: 56px;
      height: 28px;
      text-align: center;
      cursor: pointer;
    }
    .tab-item {
      width: 56px;
      height: 28px;
      cursor: pointer;
      text-align: center;
      background-size: 56px 28px;
      &.selected {
        background-image: var(--vp-c-user-box-select-img);
        &,
        & svg {
          transform: scale(1.1);
        }
      }
      &:hover {
        & svg {
          transform: scale(1.3);
        }
      }
    }
  }
  .attach {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
  }
}
</style>
