<template>
  <Circle
    :size="600"
    :trail-width="0.3"
    :stroke-width="0.3"
    :percent="percent"
    trail-color="#d6d6d66b"
    stroke-color="#43a3fb"
  >
    <div class="demo-Circle-custom">
      <TimePicker
        v-show="!isFocus"
        placeholder="选择时间"
        :model-value="timeValue"
        @on-change="timeChange"
      />
      <p style="font-size: 100px; font-weight: 1">{{ countDown }}</p>
      <p>专注关联任务</p>
      <Tag
        :title="item.title"
        v-for="(item, index) in taskList"
        :key="item.taskId"
        :name="item.taskId"
        :closable="isPause || !isFocus"
        @on-close="handleClose"
        >{{ index + 1 }}.{{ item.subTitle }}</Tag
      >
      <Button
        v-show="isPause || !isFocus"
        icon="ios-add"
        type="success"
        size="small"
        @click="handleAdd"
        ghost
        >添加任务</Button
      >
      <div style="margin-top: 20px">
        <Button
          class="btn-list"
          v-show="!isFocus"
          :size="24"
          icon="md-arrow-dropright"
          type="primary"
          @click="startCount"
          >开始专注</Button
        >
        <Button
          class="btn-list"
          v-show="isFocus && !isPause"
          :size="24"
          icon="md-pause"
          type="primary"
          @click="pauseCount"
          >暂停</Button
        >
        <Button
          class="btn-list"
          v-show="isFocus && isPause"
          :size="24"
          icon="md-arrow-dropright"
          type="primary"
          @click="continueCount"
          >继续</Button
        >
        <Button
          class="btn-list"
          v-show="isFocus"
          :size="24"
          icon="md-checkmark-circle"
          type="warning"
          @click="completeCount"
          >结束</Button
        >
      </div>
    </div>
  </Circle>
  <XSearch v-model="searchShow" :onSelected="onSelected" />
</template>
<script>
import XSearch from "@/components/XSearch";
import { $setInterval } from "@/common/util";
export default {
  components: {
    XSearch,
  },
  data() {
    return {
      timeValue: "00:10:00",
      baseTime: 10 * 60 * 1000,
      targetTime: 10 * 60 * 1000,
      taskList: [],
      searchShow: false,
      timer: null,
      isFocus: false,
      isPause: false,
    };
  },
  mounted() {},
  computed: {
    percent() {
      let timer = 100 - (this.targetTime / this.baseTime) * 100;
      return Math.floor(timer);
    },
    countDown() {
      return this.formatTime(this.targetTime);
    },
  },
  methods: {
    completeCount() {
      this.isFocus = false;
      this.isPause = false;
      this.timeValue = "00:10:00";
      this.timeChange("00:10:00");
      this.taskList = [];
      this.timer.clear();
    },
    continueCount() {
      this.isPause = false;
      this.start();
    },
    pauseCount() {
      this.isPause = true;
      this.timer.clear();
    },
    startCount() {
      if (this.targetTime === 0) {
        this.$Message.warning("请先设置专注时间");
        return false;
      }
      this.isFocus = true;
      this.start();
    },
    timeToMilliseconds(time) {
      const [hours, minutes, seconds] = time.split(":").map(Number);
      return (hours * 3600 + minutes * 60 + seconds) * 1000;
    },
    timeChange(e) {
      if (!e) {
        e = "00:00:00";
      }
      this.baseTime = this.timeToMilliseconds(e);
      this.targetTime = this.baseTime;
    },
    start() {
      this.timer = $setInterval(() => {
        console.log("setInterval setInterval setInterval");
        if (this.targetTime <= 0) {
          this.timer.clear();
          return false;
        }
        this.targetTime -= 1000;
      }, 1000);
    },
    formatTime(ms) {
      const hours = Math.floor(ms / 3600000);
      const minutes = Math.floor((ms % 3600000) / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);

      const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

      return formattedTime;
    },
    handleAdd() {
      this.searchShow = true;
    },
    handleClose(event, taskId) {
      console.log(event, taskId);
      this.taskList = this.taskList.filter((item) => item.taskId != taskId);
    },
    onSelected(taskList) {
      const mergedList = [...this.taskList, ...taskList];
      const uniqueList = Array.from(
        mergedList
          .reduce((map, item) => {
            if (!map.has(item.taskId)) {
              map.set(item.taskId, item);
            }
            return map;
          }, new Map())
          .values()
      );

      if (uniqueList.length > 10) {
        this.$Message.warning("任务最多关联10个");
        return false;
      }

      uniqueList.map((item) => {
        if (item.title?.length > 10) {
          item.subTitle = item.title.substring(0, 10) + "...";
        } else {
          item.subTitle = item.title;
        }
      });
      this.taskList = uniqueList;
    },
  },
};
</script>
<style lang="less" scoped>
.demo-Circle-custom h1 {
  color: #3f414d;
  font-size: 28px;
  font-weight: normal;
}
.demo-Circle-custom p {
  color: #657180;
  font-size: 14px;
  margin: 10px 0 15px;
}
.demo-Circle-custom span {
  display: block;
  padding-top: 15px;
  color: #657180;
  font-size: 14px;
}

.demo-Circle-custom span i {
  font-style: normal;
  color: #3f414d;
}
.task-tag {
  max-width: 220px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-list {
  margin: 0 10px;
}
</style>
