<template>
  <div class="tag-group">
    <div class="folder-item" @click="onArrowChanged">
      <XIcon 
        icon-class="close" 
        style="font-size: 16px;"
        @click.stop="onArrowChanged" 
        :class="{'rotate-arrow-up': true, 'open': tagData.expand}" 
      />
      <Text class="tag-title" ellipsis :ellipsis-config="{ tooltip: true }">标签管理</Text>
      <span style="flex: 1;"></span>
      <Icon type="md-add-circle" class="tag-add" @click.stop="onTagAddClick" />
    </div>
    <XTagItem :data="tagData" />
    <Modal 
      draggable
      sticky
      scrollable
      :mask="true"
      :width="420"
      v-model="showModal"
      :loading="modalLoading"
      :title="modalTitle"
      class="drag-modal"
      @on-ok="onModalOk">
      <template v-if="modalKey=='delete'">
        <p style="margin-bottom: 16px;">删除标签</p>
        <p style="font-size: 14px;">确定删除当前标签？（{{ modalData.tagName }}）</p>
      </template>
      <template v-else>
        <!-- <p style="margin-bottom: 12px;">{{ modalTitle }}</p> -->
        <Input class="title" v-model="modalData.tagName" :border="false" placeholder="请输入标签名称" />
        <Poptip placement="right" :width="220" trigger="hover">
          <div class="space-between edit-cell hand">
            <span style="flex: 1;">标签颜色</span>
            <span :style="'background-color:' + circleColor" class="tag-circle"></span>
            <Icon type="ios-arrow-forward" />
          </div>
          <template #content>
            <div style="text-align: center;">
              <Grid :col="6" :border="false" padding="5px 5px" style="margin-bottom: 2px;">
                <GridItem 
                  style="text-align: center;" 
                  v-for="color in colorList" 
                  :key="color"
                >
                  <span :style="'background-color:' + color" class="tag-circle hand" @click="onColorClick(color)"></span>
                </GridItem>
              </Grid>
            </div>
          </template>
        </Poptip>
        <Poptip placement="right" :width="220" trigger="hover" class="fixed" :disabled="tagEmpty">
          <div class="space-between edit-cell hand" :style="tagEmpty?'opacity: 0.5;':''">
            <span>父标签</span>
            <span>
              <span style="font-szie: 12px; margin-right: 6px;">{{ modalData.parentTagName || '不设置' }}</span>
              <Icon type="ios-arrow-forward" />
            </span>
          </div>
          <template #content>
            <CellGroup>
              <Cell v-for="tag in (tagData.list || [])" :key="tag.tagIdStr" :title="tag.tagName" @click="onTagClick(tag)">
                <template #icon>
                  <span :style="'background-color:' + (tag.color == 0 ? '#3D87DA' : colorFromValue(tag.color))" class="tag-circle"></span>
                </template>
              </Cell>
            </CellGroup>
          </template>
        </Poptip>
      </template>
    </Modal>
  </div>
</template>

<script>
import XTagItem from '@/components/XTagItem';
import { tagList } from '@/common/api';
import { colorFromValue, colorToValue } from '@/common/util';
import { tagAdd, tagUpdate, tagDelete } from '@/common/api';

const tag_colors = [
  // '#000000', '#383838', '#808080', '#A6A6A6', '#CCCCCC', '#E6E6E6',
  // '#FF5733', '#FF7E33', '#FFA733', '#FFC933', '#FFE033', '#F9ECC7',
  // '#6E8A31', '#6CB85D', '#43CF7C', '#71AFBD', '#93CDF9', '#CCE4FB',

  '#000000', '#563624', '#77787b', '#7c8577', '#a1a3a6', '#d3d7d4',
  '#472d56', '#7d5886', '#694d9f', '#6a6da9', '#9b95c9', '#afb4db',
  '#102b6a', '#2a5caa', '#2468a2', '#2585a6', '#33a3dc', '#7bbfea',
  '#007947', '#1d953f', '#008792', '#00a6ac', '#00ae9d', '#45b97c',
  '#87843b', '#cbc547', '#ffc20e', '#ffd400', '#ffe600', '#fcf16e',
  '#ae6642', '#f47920', '#f58220', '#fab27b', '#deab8a', '#fedcbd',
  '#84331f', '#c63c26', '#ed1941', '#d71345', '#ef5b9c', '#f7acbc'
];

export default {
  components: {
    XTagItem
  },
  mounted() {
    this.loadData();
  },
  provide() {
    return {
      onTagMenuClick: (type, data) => this.showTagMenu(type, data)
    }
  },
  data() {
    return {
      colorFromValue,

      modalLoading: false,
      showModal: false,
      modalData: {},
      modalKey: '',

      colorList: tag_colors
    }
  },
  computed: {
    tagEmpty() {
      return (this.tagData.list || []).length === 0;
    },
    tagData() {
      return this.$store.state.tagList;
    },
    modalTitle() {
      if (this.modalKey == 'delete') {
        return '删除';
      } else if (this.modalKey == 'edit') {
        return '编辑';
      } else {
        return '新建标签';
      }
    },
    circleColor() {
      return this.modalData.color == 0 ? '#3D87DA' : colorFromValue(this.modalData.color);
    }
  },
  methods: {
    loadData() {
      tagList().then(res => {
        if (res) {
          this.$store.commit('updateBaseTags', res);
          let list = this.dgTagTree(null, null, res);
          this.$store.commit('updateTags', list);
        }
      });
    },
    dgTagTree(id, name, list) {
      let children = [];
      list.forEach(item => {
        if (item.parentTagIdStr === id) {
          item.parentTagName = name;
          item.list = this.dgTagTree(item.tagIdStr, item.tagName, list);
          children.push(item);
        }
      });
      return children.sort((a, b) => b.positionWeight - a.positionWeight);
    },
    onArrowChanged() {
      this.tagData.expand = !this.tagData.expand;
    },
    showTagMenu(key, data) {
      this.modalKey = key;
      if (key == 'add') {
        this.modalData = {
          tagName: '',
          color: 0,
          positionWeight: 0,
          parentTagIdStr: data.tagIdStr,
          parentTagName: data.tagName
        }
      } else {
        this.modalData = Object.assign({}, data);
      } 
      this.showModal = true;
    },
    onColorClick(color) {
      this.modalData.color = colorToValue(color);
    },
    onTagClick(tag) {
      this.modalData.parentTagIdStr = tag.tagIdStr;
      this.modalData.parentTagName = tag.tagName;
    },
    onTagAddClick() {
      this.showTagMenu('add', {});
    },
    onModalOk() {
      if (this.modalKey == 'add') {
        tagAdd(this.modalData).then(res => {
          if (res) this.loadData();
        });
      } else if (this.modalKey == 'edit') {
        tagUpdate(this.modalData).then(res => {
          if (res) this.loadData();
        });
      } else if (this.modalKey == 'delete') {
        tagDelete(this.modalData.tagIdStr).then(res => {
          if (res) this.loadData();
        });
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .tag-group {
    min-height: 40px;
    max-height: 300px;
    overflow-y: overlay;
    // border-top: 1px solid #E5E5E5;
    background-color: var(--vp-c-bg-alt-soft);
    color: var(--vp-c-text-1);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .05), -1px -4px 10px 1px rgba(0, 0, 0, .09);
    border: 1px solid var(--vp-c-divider);
  }
  .folder-item {
    // height: 40px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .tag-title{
      color: var(--vp-c-text-1);
      margin-left: 8px;
    }
    .tag-add {
      font-size: 20px;
      display: none;
    }
    .trash-more {
      font-size: 14px;
      display: none;
    }
    &:hover {
      // background-color: #f3f3f5;
      .tag-add {
        display: inline !important;
      }
      .trash-more {
        display: inline !important;
      }
    }
  }
  :deep(.ivu-poptip) {
    width: 100% !important;
  }
  :deep(.ivu-poptip-rel) {
    width: 100%;
  }
  .edit-cell {
    width: 380px;
    height: 100%;
    line-height: 44px;
    padding: 0px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: var(--vp-c-bg-alt);
    }
  }
  .title:deep(.ivu-input) {
    margin-bottom: 9px;
    border-radius: 8px;
    background-color: var(--vp-c-bg-alt);
    color: var(--vp-c-text-1);
    height: 48px;
  }
  .fixed:deep(.ivu-poptip-body-content) {
    height: 200px;
    overflow-y: overlay;
  }
  :deep(.ivu-poptip-body){
    padding: 0;
  }
  :deep(.ivu-poptip-body-content) {
    padding: 10px;
  }
  :deep(.ivu-cell){
    &:hover{
      transform: scale(1.1);
      background-color: var(--vp-c-bg-alt)!important;
      .tag-circle{
        transform: scale(1.3);
      }
    }
  }
  .tag-circle {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin: 0 3px;
    &:hover{
      transform: scale(1.3);
    }
  }
  .rotate-arrow-up {
    transition: 0.3s all;
    transform: rotate(0deg);
    &.open {
      transform: rotate(180deg);
    }
  }
</style>