<template>
  <div style="display: flex;flex-direction: column;">
    <div class="clock-in-title">{{ info?.task?.title }}</div>
    <div class="clock-in-time">{{ time }} 打卡</div>
    <div style="text-align: center;margin-top: 10px">
      <Space>
        <InputNumber style="width: 50px;" :max="100" :min="0" v-model="count" />
        /
        <Text>{{info?.clock?.totalNum || 0}}</Text>
      </Space>
    </div>
    <div style="display: flex;flex-direction: row;">
      <div v-for="(value,key) in icons" style="flex: 1;padding: 20px"  :key="key">
        <Image :src="emote == key ? value[1]:value[0]" @click="select(key)" width="100%" fit="cover"/>
      </div>
    </div>
    <Input v-model="value2" type="textarea" :autosize="{minRows: 4,maxRows: 4}"  placeholder="记录一下打卡心得" />
    <Button type="primary" style="margin: 20px 0 0" @click="clockBtn">打卡</Button>
    <div style="display: flex;margin-top: 10px">
      <Button type="text" style="flex: 1" @click="giveUp">放弃</Button>
      <Button type="text" style="flex: 1" @click="onCancel">取消</Button>
    </div>
  </div>
</template>

<script>
import cryIcon from '@/assets/cry.png'
import cryActiveIcon from '@/assets/cry_active.png'
import nervousIcon from '@/assets/nervous.png'
import nervousActiveIcon from '@/assets/nervous_active.png'
import normalIcon from '@/assets/normal.png'
import normalActiveIcon from '@/assets/normal_active.png'
import goodIcon from '@/assets/good.png'
import goodActiveIcon from '@/assets/good_active.png'
import happyIcon from '@/assets/happy.png'
import happyActiveIcon from '@/assets/happy_active.png'
import {Input, Space, Text} from "view-ui-plus";
import {dayjs} from "element-plus";
import {clockedAdd, clockedUpdate} from "@/common/api";
export default {
  components: {Space, Text, Input},
	emits: ['onArrowChanged'],
  data() {
    return {
      count: 1,
      icons :{
        'cry':[cryIcon,cryActiveIcon],
        'nervous':[nervousIcon,nervousActiveIcon],
        'normal':[normalIcon,normalActiveIcon],
        'good':[goodIcon,goodActiveIcon],
        'happy':[happyIcon,happyActiveIcon],
      },
      emote:'',
      value2:'',
    }
  },
  computed:{
    info(){
      return this.$store.state.clockInfo;
    },
    time(){
      return dayjs(this.info.clockTime).format("MM月DD日")
    },
    showModal(){
      return this.$store.state.showClockDialog;
    }
  },
  watch:{
    showModal(){
      if (this.showModal){
        this.count = this.info.clock.completeNum;
        this.emote = this.info.clock.clockedMood;
        this.value2 = this.info.clock.clockedLog;
      }
    }
  },
  methods: {
    select(key) {
      this.emote = key;
    },
   async clockBtn(){
      this.info.clock.completeNum = this.count;
      this.info.clock.clockedMood = this.emote;
      this.info.clock.clockedLog = this.value2;
     if (this.info.clock.completeNum == this.info.clock.totalNum){
       this.info.clock.complete = true;
     }else{
       this.info.clock.complete = false;
     }
      if (this.info.clock.clockedHistoryId){
        const array = JSON.parse(this.info.clock.clockedDetail)
        array.push({content:`${this.info.clock.completeNum}/${this.info.clock.totalNum}${this.info.task.habitDTO.habitUnit}`,
          time:dayjs().valueOf()})
        this.info.clock.clockedDetail = JSON.stringify(array);
        await clockedUpdate(this.info.clock)
      }else{
        const array = []
        array.push({content:`${this.info.clock.completeNum}/${this.info.clock.totalNum}${this.info.task.habitDTO.habitUnit}`,
          time:dayjs().valueOf()})
        this.info.clock.clockedDetail = JSON.stringify(array);
        await clockedAdd(this.info.clock)
      }
     let copyData = JSON.parse(JSON.stringify(this.info.task));
     this.$store.commit('updateListFlag', {
       key: 'update',
       value: copyData
     });
     this.$store.commit('updateClockInfo', {});
     this.$store.commit('updateShowDialog', false);
    },
    async giveUp(){
      this.info.clock.completeNum = 0;
      this.info.clock.clockedMood = this.emote;
      this.info.clock.clockedLog = this.value2;
      this.info.clock.giveUp = true;
      if (this.info.clock.completeNum == this.info.clock.totalNum){
        this.info.clock.complete = true;
      }else{
        this.info.clock.complete = false;
      }
      if (this.info.clock.clockedHistoryId){
        const array = JSON.parse(this.info.clock.clockedDetail)
        array.push({content:`放弃了打卡`, time:dayjs().valueOf()})
        this.info.clock.clockedDetail = JSON.stringify(array);
        await clockedUpdate(this.info.clock)
      }else{
        const array = []
        array.push({content:`放弃了打卡`, time:dayjs().valueOf()})
        this.info.clock.clockedDetail = JSON.stringify(array);
        await clockedAdd(this.info.clock)
      }
      let copyData = JSON.parse(JSON.stringify(this.info.task));
      this.$store.commit('updateListFlag', {
        key: 'update',
        value: copyData
      });
      this.$store.commit('updateClockInfo', {});
      this.$store.commit('updateShowDialog', false);
    },
    onCancel(){
      this.$store.commit('updateClockInfo', {});
      this.$store.commit('updateShowDialog', false);
    },
  }
}
</script>

<style lang="less" scoped>
  .clock-in-title{
    font-size: 18px; 
    color: var(--vp-c-text-1);
    font-weight: 500;
    text-align: center;
  }
  .clock-in-time{
    font-size: 14px; 
    color:  var(--vp-c-text-2);
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
  }
</style>