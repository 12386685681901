<template>
  <div class="home-header">
    <div class="container max">
      <a class="brand" @click="onHomeClick">
        <img src="../assets/logo1.png" width="" height="48">
      </a>
      <Space size="large">
        <template v-for="value, path in menus" :key="path" >
          <Button 
            class="biz-menus"
            type="text"
            @click="onClick(path)"
            v-if="path!='/login'"
          >{{ value }}</Button>
        </template>
      </Space>
      <span></span>
      <Avatar v-if="isLogin" icon="ios-person hand" size="large" @click="onAvatar" />
      <Button v-else size="large" style="font-size: 14px;" type="primary" shape="circle" @click="onLogin">&nbsp;&nbsp;登录&nbsp;&nbsp;</Button>
    </div>
    <div class="container min">
      <a class="brand" @click="onHomeClick">
        <img src="../assets/logo1.png" width="" height="48">
      </a>
      <Icon class="icon" @click="drawShow = !drawShow" type="ios-menu" />
    </div>
    <Drawer :closable="false" v-model="drawShow" placement="top" :height="drawerHeight">
      <List style="margin-top: 60px;" size="large">
        <ListItem v-for="value, path in menus" :key="path">
          <p class="list-item" @click="onClick(path)">{{ value }}</p>
        </ListItem>
      </List>
    </Drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawShow: false
    }
  },
  computed: {
    menus() {
      let m = {
        '/download': '下载应用',
        '/upgrade': '高级会员',
        '/help': '使用手册',
        '/contact': '联系我们'
      }
      if (!this.isLogin) {
        m['/login'] = '登录';
      }
      return m;
    },
    drawerHeight() {
      return this.isLogin ? '313px' : '370px';
    },
    isLogin() {
      return this.$store.state.user.userId;
    }
  },
  methods: {
    onClick(path) {
      if (path == '/contact') {
        this.drawShow = false;
        let {href} = this.$router.resolve({path});
        window.open(href, '_blank');
      } else if (path == '/download') {
        this.$store.state.showDownload = true
      } else if (path == '/login') {
        this.$router.push({name: 'login'});
      } else if (path == '/help') {
        window.open('https://doc.yimutodo.com/index.html', '_blank');
      } else {
        this.$Message.info('努力开发中~');
      }
    },
    onAvatar() {
      this.$router.push('/manager/work');
    },
    onLogin() {
      this.$router.push({ name: 'login'});
    },
    onHomeClick() {
      this.$router.push({name: 'home'});
    }
  }
}
</script>

<style lang="less" scoped>
  .home-header {
    background-color: white;
    // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
    min-height: 80px;
    z-index: 1001;

    .container {
      &.max {
        display: flex;
      }
      &.min {
        display: none;
      }
      max-width: 1280px;
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      .brand {
        display: flex;
        align-items: center;
        .brand_logo {
          width: 40px;
          height: 40px;
          vertical-align: middle;
          margin-right: 16px;
        }
        .brand_text {
          height: 32px;
          width: 140px;
          vertical-align: middle;
        }
      }
      .menu {
        border: none;
        top: 1px;
        .menu-item {
          font-weight: 500;
          font-size: 15px;
        }
      }
      .icon {
        font-size: 30px; 
        cursor: pointer; 
        color: #3D87DA;
      }
    }
    @media (max-width: 1440px) {
      .container {
        max-width: 1144px;
      }
    }
    @media (max-width: 768px) {
      min-height: 64px;
      .container {
        width: 94%;
        &.max {
          display: none;
        }
        &.min {
          display: flex;
        }
      }
    }
  }
  p.list-item {
    padding: 0px 20px;
    color: black;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
  }
  .biz-menus{
    font-size: 14px;
    color: #311F09;
    font-weight: 500;
  }
</style>