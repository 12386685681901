<template>
  <div class="task-filter head">
    <Row
      class="task-filter-item"
      @click="checkType(1)"
      :class="{ active: filter.checkTypeVal === 1 }"
    >
      <Col span="8">
        <XIcon icon-class="content" style="font-size: 16px" />
        <span class="content">所有</span>
      </Col>
      <Col span="12"></Col>
      <Col span="4" class="extra">
        <Icon
          :type="
            filter.checkTypeVal === 1
              ? 'md-radio-button-on'
              : 'md-radio-button-off'
          "
          :size="20"
        />
      </Col>
    </Row>
    <Row
      class="task-filter-item"
      @click="checkType(2)"
      :class="{ active: filter.checkTypeVal === 2 }"
    >
      <Col span="8">
        <XIcon icon-class="person" style="font-size: 18px" />
        <span class="content">指派给我</span>
      </Col>
      <Col span="12"></Col>
      <Col span="4" class="extra">
        <Icon
          :type="
            filter.checkTypeVal === 2
              ? 'md-radio-button-on'
              : 'md-radio-button-off'
          "
          :size="20"
        />
      </Col>
    </Row>
  </div>
  <div class="task-filter custom">
    <Row
      class="task-filter-item"
      @click="checkType(3)"
      :class="{ active: filter.checkTypeVal === 3 }"
    >
      <Col span="8">
        <XIcon icon-class="other" style="font-size: 18px" />
        <span class="content">自定义</span>
      </Col>
      <Col span="12"></Col>
      <Col span="4" class="extra">
        <Icon
          :type="
            filter.checkTypeVal === 3
              ? 'md-radio-button-on'
              : 'md-radio-button-off'
          "
          :size="20"
        />
      </Col>
    </Row>
    <Row class="task-filter-item">
      <Col span="5" class="filter-label">
        <Icon type="ios-options" />
        <span class="content">类型</span>
      </Col>
      <Col span="19">
        <Select v-model="filter.type" multiple :max-tag-count="3">
          <Option
            v-for="item in option.type"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </Col>
    </Row>
    <Row class="task-filter-item">
      <Col span="5" class="filter-label">
        <Icon type="ios-paper" />
        <span class="content">清单</span>
      </Col>
      <Col span="19">
        <Select
          v-model="filter.todo"
          :loading="loading.todo"
          multiple
          :max-tag-count="2"
        >
          <Option
            v-for="option in option.todo"
            :value="option.projectIdStr"
            :key="option.projectIdStr"
            >{{ option.name }}</Option
          >
        </Select>
      </Col>
    </Row>
    <Row class="task-filter-item">
      <Col span="5" class="filter-label">
        <Icon type="md-calendar" />
        <span class="content">日期</span>
      </Col>
      <Col span="19">
        <DatePicker
          v-model="filter.date"
          :options="option.datePicker"
          @on-change="dateTransfer"
          type="datetimerange"
          format="yyyy-MM-dd HH:mm"
          placement="bottom-end"
          placeholder="请选择区间"
          style="width: 100%"
        />
      </Col>
    </Row>
    <Row class="task-filter-item">
      <Col span="5" class="filter-label">
        <Icon type="ios-link" />
        <span class="content">标签</span>
      </Col>
      <Col span="19">
        <TreeSelect v-model="filter.tag" multiple :data="option.tag" transfer />
      </Col>
    </Row>
    <Row class="task-filter-item">
      <Col span="5" class="filter-label">
        <Icon type="ios-people" />
        <span class="content">成员</span>
      </Col>
      <Col span="19">
        <Select v-model="filter.member" multiple :max-tag-count="2">
          <Option
            v-for="item in option.member"
            :value="item.userId"
            :key="item.userId"
            >{{ item.nickName
            }}<span v-if="item.userId == myUserId">(我)</span></Option
          >
        </Select>
      </Col>
    </Row>
  </div>

  <!-- <Poptip placement="right" :width="240" trigger="hover">
      <div style="margin-top: 12px" class="space-between edit-cell hand">
        <span style="display: flex; align-items: center">类型</span>
        <span v-if="editData.types.length > 0">
          <Text
            style="margin-right: 6px; max-width: 140px"
            ellipsis
            :ellipsis-config="{ tooltip: true }"
          >
            <span class="primary-color">{{ showType }}</span>
          </Text>
          <Icon
            type="ios-close-circle"
            class="primary-color"
            @click.stop="editData.types = []"
          />
        </span>
        <span v-else>
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <template #content>
        <CellGroup>
          <Cell
            v-for="(item, index) in allTypes"
            :key="index"
            :title="item.label"
            @click="onTypeClick(item)"
          >
            <template #icon>
              <Icon
                :type="
                  typeCheck(item)
                    ? 'ios-checkmark-circle'
                    : 'md-radio-button-off'
                "
                class="primary-color"
                style="font-size: 16px"
              />
            </template>
          </Cell>
        </CellGroup>
      </template>
    </Poptip>
    <Dropdown trigger="hover" placement="right-start">
      <div class="space-between edit-cell hand">
        <span style="display: flex; align-items: center">标签</span>
        <span v-if="editData.tags.length > 0">
          <Text
            style="margin-right: 6px; max-width: 160px"
            ellipsis
            :ellipsis-config="{ tooltip: true }"
            class="primary-color"
          >
            <span class="primary-color">{{ showTag }}</span>
          </Text>
          <Icon
            type="ios-close-circle"
            class="primary-color"
            @click.stop="editData.tags = []"
          />
        </span>
        <span v-else>
          <Icon type="ios-arrow-forward" />
        </span>
      </div>
      <template #list>
        <XTagMenu
          :menuWidth="168"
          :onSelect="onTagClick"
          :dataList="$store.state.tagList.list"
          :selectedList="editData.tags"
        />
      </template>
    </Dropdown> -->
</template>

<script>
// import XTagMenu from "@/components/XTagMenu";
import "@/common/util";

// import "element-plus/es/components/date-picker/style/css";
// import { ElDatePicker, ElConfigProvider } from "element-plus";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import { tagList, getMember } from "@/common/api";
import "dayjs/locale/zh-cn";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          checkTypeVal: 1,
          type: [],
          todo: [],
          date: [],
          tag: [],
          member: [],
        };
      },
    },
    showFiltra: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  components: {
    // ElDatePicker,
    // ElConfigProvider,
    // XTagMenu,
  },
  mounted() {
    // this.initData();
    this.getTagList();
  },
  data() {
    return {
      myUserId: this.$store.state.user.userId,
      filter: {
        checkTypeVal: 1, //筛选类型 1.所有 2.指派给我 3.自定义
        type: [],
        todo: [],
        date: [],
        tag: [],
        member: [],
      },
      loading: {
        todo: false,
        tag: false,
        member: false,
      },
      option: {
        todo: [],
        tag: [],
        member: [],
        datePicker: {
          shortcuts: [
            {
              text: "今天",
              value() {
                const start = new Date();
                start.setHours(0, 0, 0, 0);

                const end = new Date();
                end.setHours(23, 59, 59, 999);

                return [start, end];
              },
            },
            {
              text: "明天",
              value() {
                const start = new Date();
                start.setDate(start.getDate() + 1);
                start.setHours(0, 0, 0, 0);

                const end = new Date();
                end.setDate(end.getDate() + 2);
                end.setHours(0, 0, 0, 0);
                end.setSeconds(end.getSeconds() - 1);
                return [start, end];
              },
            },
            {
              text: "本周",
              value() {
                const start = new Date();
                const end = new Date();

                const today = start.getDay();
                const diffToMonday =
                  start.getDate() - today + (today === 0 ? -6 : 1);

                start.setDate(diffToMonday);
                start.setHours(0, 0, 0, 0);

                end.setDate(diffToMonday + 6);
                end.setHours(23, 59, 59, 999);

                return [start, end];
              },
            },
            {
              text: "近一周",
              value() {
                const start = new Date();
                const end = new Date();
                end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                return [start, end];
              },
            },
            {
              text: "本月",
              value() {
                const start = new Date();
                start.setDate(1);
                start.setHours(0, 0, 0, 0);

                const end = new Date();
                end.setMonth(end.getMonth() + 1);
                end.setDate(0);
                end.setHours(23, 59, 59, 999);

                return [start, end];
              },
            },
            {
              text: "近一个月",
              value() {
                const start = new Date();
                const end = new Date();
                end.setMonth(end.getMonth() + 1);
                end.setHours(23, 59, 59, 999);
                return [start, end];
              },
            },
          ],
        },
        type: [
          {
            value: 0,
            label: "任务",
          },
          {
            value: 1,
            label: "笔记",
          },
          {
            value: 2,
            label: "习惯",
          },
        ],
      },
      locale: zhCn,
      // switchValue: false,
      // typeMap: {
      //   0: "任务",
      //   1: "笔记",
      //   2: "习惯",
      // },
      // allTypes: [
      //   {
      //     value: 0,
      //     label: "任务",
      //   },
      //   {
      //     value: 1,
      //     label: "笔记",
      //   },
      //   {
      //     value: 2,
      //     label: "习惯",
      //   },
      // ],
      // editData: { tags: [], types: [] },
    };
  },
  watch: {
    data() {
      this.initData();
    },
    showFiltra(val) {
      if (val) {
        this.getData();
      }
    },
  },
  computed: {
    // showType() {
    //   if (this.editData.types.length > 0) {
    //     let list = this.editData.types.map((type) => this.typeMap[type]);
    //     return list.join(",");
    //   }
    //   return "";
    // },
    // showTag() {
    //   let list = [];
    //   this.editData.tags.forEach((e1) => {
    //     this.$store.state.baseTagList.forEach((e2) => {
    //       if (e1 == e2.tagIdStr) {
    //         list.push(e2.tagName);
    //       }
    //     });
    //   });
    //   return list.join(",");
    // },
  },
  methods: {
    dateTransfer() {
      if (!this.filter.date.join("")?.length) {
        this.filter.date = [];
        return false;
      }

      this.filter.date = this.filter.date.map((item) => {
        return new Date(item || 0).getTime();
      });
    },
    getData() {
      const projectList = this.$store.state.baseProjectList.filter((item) => {
        return item.projectType === 0;
      });

      this.option.todo = projectList;
      const projectIds = projectList.map((obj) => obj.projectIdStr);
      this.getMemberList(projectIds);
    },
    initData() {
      let baseData = {
        checkTypeVal: 1,
        type: [],
        todo: [],
        date: [],
        tag: [],
        member: [],
      };
      this.filter = Object.assign(baseData, this.data || {});
      // if (!this.editData.startTime) {
      //   this.editData.startTime = new Date().setHours(0,0,0,0);
      // }
    },
    convertToHierarchy(tagsData=[]) {
      const hierarchicalTags = {};
      const tagDict = {};
      tagsData.forEach((tag) => {
        tag.value = tag.tagIdStr;
        tag.title = tag.tagName;
        tagDict[tag.tagIdStr] = tag;
        tag.children = [];
      });

      tagsData.forEach((tag) => {
        if (tag.parentTagId !== null) {
          tagDict[tag.parentTagId]?.children.push(tag);
        } else {
          hierarchicalTags[tag.tagIdStr] = tag;
        }
      });
      return Object.values(hierarchicalTags);
    },
    checkType(type) {
      this.filter.checkTypeVal = type;
    },
    getTagList() {
      this.loading.tag = true;
      tagList().then((res) => {
        this.loading.tag = false;
        this.option.tag = this.convertToHierarchy(res);
      });
    },
    getMemberList(projectIds) {
      this.loading.member = true;
      getMember({ projectIds }).then((res) => {
        this.loading.member = false;
        const memberList = [];
        res.map((item) => {
          item.members.map((member) => {
            memberList.push(member);
          });
        });
        let uniqueArray = memberList.reduce((acc, obj) => {
          acc[obj.userId] = obj;
          return acc;
        }, {});
        uniqueArray = Object.values(uniqueArray);
        this.option.member = uniqueArray;
      });
    },
    handleClick() {},
    // startTimeDisable(date) {
    //   return this.editData.endTime && date.getTime() > this.editData.endTime;
    // },
    // endTimeDisable(date) {
    //   return date.getTime() < this.editData.startTime;
    // },
    // onTypeClick(item) {
    //   let index = this.editData.types.indexOf(item.value);
    //   if (index > -1) {
    //     this.editData.types.splice(index, 1);
    //   } else {
    //     this.editData.types.push(item.value);
    //   }
    // },
    // typeCheck(item) {
    //   return this.editData.types.indexOf(item.value) > -1;
    // },
    // onTagClick(tag) {
    //   let index = this.editData.tags.indexOf(tag.tagIdStr);
    //   if (index >= 0) {
    //     this.editData.tags.splice(index, 1);
    //   } else {
    //     this.editData.tags.push(tag.tagIdStr);
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
:deep(.ivu-input) {
  background-color: var(--vp-c-bg);
  border-color: var(--vp-c-divider);
  color: var(--vp-c-text-1);
}
:deep(.ivu-picker-confirm-time) {
  background-color: transparent!important;
}
:deep(.ivu-btn:hover) {
  background-color: transparent;
}
:deep(.ivu-poptip) {
  width: 100% !important;
}
:deep(.ivu-poptip-rel) {
  width: 100%;
}
.task-filter {
  background-color: var(--vp-c-bg-soft);
  border-radius: 8px;
  &.custom {
    // background-color: rgba(246, 246, 246, 1);
    margin-top: 2rem;
    .task-filter-item {
      cursor: auto;
      &:first-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
      &.active,
      &:first-child:hover {
        cursor: pointer;
        // background-color: #e7e7e7;
        background-color: var(--vp-c-bg-alt);
      }
    }
  }
  &.head {
    .task-filter-item {
      &:first-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
      &:last-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
      &.active,
      &:hover {
        background-color: var(--vp-c-bg-alt);
      }
    }
  }
  .task-filter-item {
    // background-color: rgba(246, 250, 253, 1);
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s linear;
    display: flex;
    align-items: center;
    .filter-label {
      display: flex;
      align-items: center;
    }
    .content {
      margin-left: 10px;
    }
    .extra {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.edit-cell {
  width: 380px;
  height: 100%;
  line-height: 44px;
  padding: 0px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  &:hover {
    background: #f6f6f6;
  }
}
</style>
