<template>
  <div class="menu">
    <XUserBox />
    <XFolder v-if="routeName=='work'" />
    <XCalenderMenu v-else-if="routeName=='calendar'" />
    <XGroupMenu v-else-if="routeName=='group'" />
  </div>
</template>

<script>
/// 左侧菜单
import XUserBox from './XUserBox';
import XFolder from './XFolder';
import XCalenderMenu from './XCalenderMenu';
import XGroupMenu from './XGroupMenu';

export default {
  components: { 
    XUserBox,
    XFolder,
    XCalenderMenu,
    XGroupMenu
  },
  computed: {
    routeName() {
      return this.$route.name;
    }
  }
}
</script>

<style lang="less" scoped>
  .menu {
    position: relative;
    min-width: 240px;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--vp-c-bg);
    width: auto;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
</style>