<template>
  <div class="user-menu">
    <div class="header-group">
      <div style="position: relative; width: 40px;">
        <Image 
        v-if="userInfo.avatarUrl"
        :src="userInfo.avatarUrl" 
        width="40" 
        height="40"
        style="border-radius: 20px; overflow: hidden;" />
        <Avatar v-else :src="require('../assets/header_avatar.png')" icon="ios-person" />
        <XIcon class="attach" v-if="userInfo.memberType!='NORMAL'" iconClass="user_member" />
      </div>
      <Text class="title" ellipsis :ellipsis-config="{ tooltip: true }">{{ userInfo.nickName }}</Text>
      <Text class="subtitle" ellipsis :ellipsis-config="{ tooltip: true }">坚持记录的第{{ userDuration }}天</Text>
      <Divider style="margin: 16px 0; color: #a6a6a6" dashed />
    </div>
    <CellGroup>
      <Cell 
        v-for="(value, key) in settingConfig"
        class="setting-cell"
        :key="key" 
        :title="value" 
        @click="menuclick(key)" />
    </CellGroup>
  </div>
</template>

<script>
import { logout, channelEvent } from '@/common/api';

export default {
  data() {
    return {
      settingConfig: {
        'home': '返回首页',
        'settings': '个人账号',
        'notices': '提醒设置',
        'preferences': '偏好设置',
        // 'member': '会员中心',
        // 'tickets': '建议与反馈',
        // 'about': '关于我们',
        'contact': '联系我们',
        'logout': '退出登录',
      },
    }
  },
  methods: {
    menuclick(key) {
      if (key == 'logout') {
        logout();
        this.$store.commit('userLogout');
        this.$router.push('/login');
        channelEvent('logout');
        setTimeout(() => {
          window.location.reload();
        }, 0);
        
      } else if (key == 'contact') {
        let path = '/' + key;
        let {href} = this.$router.resolve({path});
        window.open(href, '_blank');
      } else {
        this.$router.push({name: key});
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user;
    },
    userDuration() {
      if (this.userInfo.registerTime) {
        let duration = Date.now() - this.userInfo.registerTime;
        return Math.ceil(duration / (1000 * 60 * 60 * 24));
      }
      return 1;
    }
  }
}
</script>

<style lang="less" scoped>
  .user-menu {
    width: 138px;
    line-height: normal;
    .header-group {
      padding: 6px 16px 0px 16px;
      display: flex;
      flex-direction: column;
    }
    .title {
      // color: #183759;
      color: var(--vp-c-text-1);
      font-size: 14px;
      font-weight: 600;
      margin: 10px 0px 4px 0px;
      line-height: 21px !important;
    }
    .subtitle {
      // color: #969EA8;
      color: var(--vp-c-text-3);
      font-size: 12px;
      line-height: 18px !important;
    }
    .attach {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
    }
    .setting-cell{
      color: var(--vp-c-text-2);
      border-radius: 8px;
    }
  }
</style>