import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ViewUIPlus from 'view-ui-plus'
import XIcon from '@/components/XIcon'
import ContextMenu from '@imengyu/vue3-context-menu'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import './styles.less'
import './theme.less'
import './iviewTheme.less'

const app = createApp(App)

app.component('XIcon', XIcon)

app.use(store)
  .use(router)
  .use(ViewUIPlus)
  .use(ContextMenu)
  .mount('#app')
// 屏蔽错误信息
app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;