<template>
	<div class="contact">
		<h3>联系我们</h3>
		<p>我们欢迎所有用户的宝贵建议与需求反馈</p>
		<ul style="max-width: 800px; margin: 0 auto;">
			<li v-for="value, key in contacts" :key="key">
				<span style="width: 20%;">{{ key }}</span>
				<span style="width: calc(80% - 80px);">{{ value }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			contacts: {
				'邮箱': 'yimuyuzhou@163.com',
				'QQ群': '903318174',
				'公众号': '一木清单'
			}
		}
	},
	methods: {
		onBackClick() {
			this.$router.go(-1);
		}
	}}
</script>

<style lang="less" scoped>
	.contact {
		text-align: center;
		background-color: #fff;
		height: 100%;
		padding: 10% 10%;
		overflow-y: scroll;
		.contact-btn {
      border-radius: 8px;
      font-size: 14px;
      &.back {
        position: absolute;
        top: 24px;
        left: 24px;
      }
      &.high {
        color: #FF8F8F;
        border-color: #FF8F8F;
      }
    }
		p {
			padding: 20px 0px 48px 0px;
		}
		ul li span {
			display: inline-block;
			padding: 28px 0px;
			background-color: #D0CCC722;
			border-radius: 12px;
			margin: 16px;
			color: #808080;
			&:last-child {
				color: #383838;
				padding: 28px 32px;
				text-align: left;
			}
		}
	}
</style>