<template>
  <div class="view">
    <div class="header">
      <Space direction="vertical">
        <span style="font-size: 22px">番茄时钟</span>
      </Space>
      <span style="flex: 1"></span>
      <Space>
        <Dropdown trigger="click" placement="bottom-end">
          <Icon type="md-more" size="22" class="more-icon" />
          <template #list>
            <DropdownMenu class="dropdown-list">
              <DropdownItem @click="onMoreClick">
                {{ !showFlip ? "卡片形式" : "圆弧形式" }}
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
      </Space>
    </div>
    <div class="main">
      <div class="left">
        <XFlipClock v-if="showFlip" />
        <XCircle v-if="!showFlip" />
      </div>
      <div class="right">
        <div class="day-count">
          <p class="sub-title">
            <Icon type="md-done-all" :size="18" />专注信息
            <DatePicker
              size="small"
              type="daterange"
              :options="timeRange"
              placement="bottom-end"
              placeholder="请选择专注时间区间"
              style="margin-left: 10px; width: 250px"
            />
          </p>
          <div class="sub-wrapper">
            <p class="sub-content">
              专注<span class="highlight-num">
                <CountUp :end="1234" :duration="1" ref="count" /></span
              >次
            </p>
            <p class="sub-content">
              专注<span class="highlight-num">25: 32</span>分钟
            </p>
          </div>

          <!-- <Progress
            :percent="25"
            :stroke-width="5"
            :stroke-color="['#108ee9']"
          /> -->
        </div>
        <div class="content">
          <p class="sub-title"><Icon type="ios-list" :size="22" />专注记录</p>
          <div class="sub-wrapper">
            <TimelineItem
              color="green"
              v-for="item in absorbsDataList"
              :key="item.absorbedId"
            >
              <p class="time" style="font-size: 14px">{{ item.timeRange }}</p>
              <div
                v-for="(subItem, index) in item.absorbedInfoList"
                :key="index"
              >
                <div v-for="ssb in subItem.taskList" :key="ssb.taskId">
                  {{ ssb.title }}
                </div>
              </div>
            </TimelineItem>
          </div>
        </div>
        <div class="chart">
          <p class="sub-title">
            <Icon type="ios-pie-outline" :size="18" />专注分布
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XFlipClock from "@/components/XFlipClock";
import XCircle from "@/components/XCircle";
import { absorbsList, taskList } from "@/common/api";
export default {
  components: {
    XFlipClock,
    XCircle,
  },
  async mounted() {
    this.tasks = await taskList();
    this.absorbs = await absorbsList();
    this.dataAssemble();
  },
  data() {
    return {
      showFlip: false,
      timeRange: {
        shortcuts: [
          {
            text: "1 week",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "1 month",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "3 months",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
      absorbs: [],
      tasks: [],
      absorbsDataList: [],
    };
  },
  methods: {
    async dataAssemble() {
      let absorbs = this.absorbs;
      absorbs = absorbs.filter((item) => {
        return item.startTime > 0 && item.endTime > 0;
      });

      const tasksMap = new Map(this.tasks.map((task) => [task.taskId, task]));
      absorbs.forEach((item) => {
        item.timeRange = `${this.formatTimestamp(item.updateTime)}`;

        if (item.absorbedInfoList?.length) {
          item.absorbedInfoList.forEach((info) => {
            info.taskList = [];

            if (info.taskIds?.length) {
              const taskList = info.taskIds
                .map((taskId) => tasksMap.get(taskId))
                .filter((task) => task !== undefined);

              info.taskList.push(...taskList);
            }
          });
        }
      });

      console.log(absorbs);
      this.absorbsDataList = absorbs;
    },
    onMoreClick() {
      this.showFlip = !this.showFlip;
    },
    formatTimestamp(timestamp) {
      // 将时间戳直接转换为 Date 对象
      const inputDate = new Date(timestamp);
      const currentDate = new Date(); // 当前时间

      // 获取日期信息
      const inputYear = inputDate.getFullYear();
      const inputMonth = inputDate.getMonth() + 1; // 月份从0开始
      const inputDay = inputDate.getDate();

      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();

      // 判断是否是同一天
      if (
        inputYear === currentYear &&
        inputMonth === currentMonth &&
        inputDay === currentDay
      ) {
        return `${inputDay}日`;
      }

      // 判断是否是同一月，不同年
      if (inputYear === currentYear && inputMonth === currentMonth) {
        return `${inputDay}日`;
      }

      // 跨年
      if (inputYear !== currentYear) {
        return `${inputYear}年${inputMonth}月${inputDay}日`;
      }

      // 跨月
      return `${inputMonth}月${inputDay}日`;
    },
  },
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  width: 100%;
  background-color: var(--vp-c-bg-alt);
  color: var(--vp-c-text-1);
  display: flex;
  flex-direction: column;
  .header {
    padding: 20px;
    display: flex;
    background-color: var(--vp-c-bg-elv);
    height: 70px;
  }
  .main {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: calc(100% - 70px);
    .left {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .right {
      width: 450px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 20px;
    }
    .day-count {
      height: 137px;
      font-size: 12px;
      border-radius: 12px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      box-shadow: var(--vp-shadow-6);
      box-sizing: border-box;
      padding: 15px;
      display: flex;
      flex-direction: column;
      color: var(--vp-c-text-1);
      box-shadow: 3px 5px 12px 0px rgb(0 0 0 / 4%);
      :deep(.ivu-progress-inner) {
        background-color: #9494942e;
      }
      .sub-title {
        font-size: 12px;
        display: flex;
        align-items: flex-end;
        gap: 5px;
      }
      .sub-wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        .sub-content {
          font-size: 12px;
          font-weight: 100;
          margin-right: 20px;
          &:last-child {
            margin-right: 0px;
          }
          .highlight-num {
            font-size: 42px;
            color: #3d87da;
          }
        }
      }
    }
    .content {
      flex-grow: 1;
      font-size: 12px;
      border-radius: 12px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      box-shadow: var(--vp-shadow-6);
      box-sizing: border-box;
      padding: 15px;
      display: flex;
      flex-direction: column;
      color: var(--vp-c-text-1);
      overflow: hidden;
      box-shadow: 3px 5px 12px 0px rgb(0 0 0 / 4%);
      .sub-title {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .sub-wrapper {
        height: 100%;
        padding: 0 20px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        :deep(.ivu-timeline-item-head) {
          background-color: var(--vp-c-bg);
        }
        :deep(.ivu-timeline-item-tail) {
          border-color: var(--vp-c-divider);
        }
      }
    }
    .chart {
      height: 400px;
      font-size: 12px;
      border-radius: 12px;
      background-color: var(--vp-c-bg);
      color: var(--vp-c-text-1);
      box-shadow: var(--vp-shadow-6);
      box-sizing: border-box;
      padding: 15px;
      display: flex;
      flex-direction: column;
      color: var(--vp-c-text-1);
      box-shadow: 3px 5px 12px 0px rgb(0 0 0 / 4%);
      .sub-title {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}
</style>
