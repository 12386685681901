<template>
  <div class="map">
    <div class="header">
      <Space direction="vertical">
        <span style="font-size: 22px">任务地图</span>
      </Space>
      <span style="flex: 1"></span>
      <!-- <Space>
        <Switch size="small" v-model="showComplete" @on-change="onSwitchChange" />
        <span style="font-size: 14px;">显示已完成</span>
        <XIcon iconClass="more" />
      </Space> -->
      <Dropdown trigger="click" placement="bottom-end">
        <!-- <XIcon icon-class="more-col" style="font-size: 16px;"/> -->
        <Icon type="md-more" size="22" class="more-icon"/>
        <template #list>
          <DropdownMenu class="dropdown-list" >
            <DropdownItem 
              @click="onMoreClick">
              {{ showComplete ? '隐藏已完成' : '显示已完成' }}
            </DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
    <div id="xTaskMap"></div>
    <Modal
      v-if="showModal"
      class="task-detail-modal"
      title=" "
      :mask="true"
      :width="520"
      draggable
      sticky
      scrollable
      v-model="showModal"
      @on-ok="onModalOk">
      <XToastTaskDetail :task="task" />
    </Modal>
  </div>
</template>

<script>

import { shallowRef } from 'vue'
import { 
  userInfo,
  taskList,
  taskAdd,
  addressList,
  noticeAdd,
  repeatAdd,
  addressAdd
} from '@/common/api';
import XToastTaskDetail from '@/components/XToastTaskDetail'
// import { formatTime } from "@/common/util";

let markerLayer;

export default {
  setup() {
    const map = shallowRef(null)
    return{
      map,
    }
  },
  components: {
    XToastTaskDetail
  },
  mounted() {
    this.$nextTick( this.setMap())
   
  },
  data() {
    return {
      showComplete: false,
      geocoder: {},
      showModal: false,
      task: {},
      normalList: [],
      completeList: [],
      AMap: {},

      longitude: 116.397128,
      latitude: 39.916527,
    }
  },
  methods: {
    getPosition(){
      var geolocation = new window.qq.maps.Geolocation('OX2BZ-LLMCJ-NABFD-DONPY-7QKIT-SJFB5', 'yimutodo');
      geolocation.getLocation((res)=>{
        const {lng, lat} = res
        this.map.setCenter([lat, lng])
      }, (e)=>{
        console.log(e)
      });
    },
    setMap() {
      if (this.map) return;
      var element = document.getElementById("xTaskMap");
      if (!element) return;
      var myLatlng = new window.TMap.LatLng(this.latitude, this.longitude)
      var myOptions = {
        zoom: 10, 
        center: myLatlng,
        mapStyleId: 'style2'
      }
      this.map = new window.TMap.Map(element, myOptions);
      this.map.on("click", this.getAddress)
      markerLayer = new window.TMap.MultiMarker({
        map: this.map,
        styles: {
          marker: new window.TMap.MarkerStyle({
            width: 34,
            height: 46,
            anchor: { x: 17, y: 23 },
            size: 16,
            direction: 'bottom',
            offset: { x: 0, y: 8 },
            strokeColor: '#fff', 
            strokeWidth: 2,
          }),
        },
        geometries: [],
      });
      markerLayer.on("click", this.markerClick);
      this.getPosition()
      this.loadData();
    },
    markerClick(event) {
      this.task = event.geometry.properties;
      this.showModal = true;
    },
    getAddress(event) {
      if (this.showModal) return;
      var geocoder = new window.TMap.service.Geocoder();
      geocoder.getAddress({ location: event.latLng }).then((result) => {
        var address = result.result.formatted_addresses;
        var formatAddress = {
          lng: event.latLng.getLng().toFixed(6),
          lat: event.latLng.getLat().toFixed(6),
          name: address.recommend,
          address: address.standard_address
        }
        this.onLocationClick(formatAddress);
      });
    },
    async loadData() {
      let info = await userInfo();
      if (!info || !info.userId) return;
      let addresses = await addressList();
      if (!addresses) return;
      let projectId = '-2' + this.$store.state.user.userId;
      let tasks = await taskList(projectId);
      if (tasks) {
        this.completeList = [];
        this.normalList = [];
        tasks.forEach(element => {
          if (element.addressIdStr) {
            addresses.forEach(inElement => {
              if (inElement.addressIdStr == element.addressIdStr) {
                element.addressModel = inElement;
              }
            });
            if (element.completeTime > 0 || element.giveUp) {
              this.completeList.push(element);
            } else {
              this.normalList.push(element);
            }
          }
        });
        this.drawTask();
      }
    },
    drawTask() {
      let list = [];
      if (this.showComplete) {
        list = [...this.normalList, ...this.completeList];
      } else {
        list = this.normalList;
      }
      list.forEach(element => {
        if (!element.addressModel) return;
        // let time = formatTime(element.startTime, element.endTime, element.completeTime == 0)
        let title = element.title && element.title.length ? element.title : '无标题';
        markerLayer.add({
          styleId: 'marker',
          position: new window.TMap.LatLng(element.addressModel.latitude, element.addressModel.longitude),
          properties: element,
          content: title
        });
      });
    },
    onModalOk() {
      if (!this.task.taskIdStr) {
        taskAdd(this.task).then(res => {
          if (res) {
            if (this.task.notices && this.task.notices.length > 0) {
              this.task.notices.forEach(element => {
                element.taskIdStr = res;
                element.taskId = parseInt(res, 10);
                noticeAdd(element);
              });
            }
            if (this.task.repeat && Object.keys(this.task.repeat).length > 0) {
              this.task.repeat.taskIdStr = res;
              this.task.repeat.taskId = parseInt(res, 10);
              repeatAdd(this.task.repeat);
            }
            this.loadData();
          }
        });
      } else {
        this.loadData();
      }
    },
    onLocationClick(location) {
      addressAdd({
        positionWeight: 1,
        poiAddress: location.address,
        latitude: location.lat,
        longitude: location.lng,
        totalAddress: location.address,
        userId: this.$store.state.user.userId,
      }).then(res => {
        if (res) {
          this.task = {
            giveUp: false,
            completeTime: 0,
            title: '',
            level: 0,
            projectIdStr: '1' + this.$store.state.user.userId,
            positionWeight: 10,
            taskType: 0,
            addressIdStr: res
          };
          this.showModal = true;
        }
      });
    },
    onSwitchChange() {
      this.drawTask();
    },
    onMoreClick() {
      this.showComplete = !this.showComplete;
      this.drawTask();
    }
  },
}
</script>

<style lang="less" scoped>
  .map {
    height: 100%;
    width: 100%;
    background-color: #fff;
    .header {
      padding: 20px;
      display: flex;
    }
    #xTaskMap {
      width: 100%;
      height: calc(100% - 75px);
      min-height: 300px;
      min-width: 300px;
    }
  }
</style>