import { createStore } from "vuex";

const store = createStore({
  state: {
    user: {}, // 用户
    iconList: [], // 图标数组
    addressList: [], // 地址数组
    baseTagList: [], // 接口初始未处理的标签列表
    tagList: {
      expand: false,
      list: [],
    }, // 标签数组
    baseProjectList: [], // 接口获取未处理的清单列表
    projectList: [], // 清单列表
    taskList: [], // 任务列表
    // 打开的文件夹字典
    openFolder: {},
    // 选中的项目
    currentProject: {},
    // 项目的排序字典
    projectTypes: {},

    // 打开的任务字典
    openTask: {},
    // 任务配置
    taskDetailMode: false,
    taskCompleteMode: false,
    taskSortId: false,
    // 当前选中的task
    currentTask: {},

    // 操作任务后的列表通知
    listFlag: {},
    // 用于右键弹窗的数据对象，包含event和task两数据
    menuData: {},
    // 用于四象限中的任务弹窗展示
    toastTask: {},

    showDownload: false,
    showClockDialog: false,
    clockInfo: {},
    focusId: null,
    projectCount: false,
    toastTaskUpdatTime: 0,
    projectTimestamp: "",
    // handleProjectList(){
    //   if (this.projectList && this.projectList.length) {
    //     let projectList = []
    //     projectList = JSON.parse(JSON.stringify(this.projectList))
    //     let list = projectList[2].list;
    //     if (list && list.length) {
    //       list[0].divided = true;
    //     }
    //     return list;
    //   } else {
    //     return []
    //   }
    // }
  },
  getters: {
    // get方法
    // message(state) {
    //   return state.count % 2 === 0 ? '偶数': '奇数';
    // }
    // store.getters.message

    // 当前选中项目的的排序方式
    currentSortType(state) {
      return state.projectTypes[state.currentProject.id] || 0;
    },
    // 获取通用清单列表
    normalProjectList(state) {
      if (state.projectList && state.projectList.length == 3) {
        let list = state.projectList[2].list;
        if (list && list.length) {
          let newList = JSON.parse(JSON.stringify(list));
          newList[0].divided = true;
          return newList;
        }
      }
      return [];
    },
  },
  mutations: {
    // 提交修改
    // increase(state, {count}) {
    //   state.count = count;
    // }
    // store.commit('increase', {count: 2})

    // 更新用户信息
    updateInfo(state, param) {
      state.user = param;
    },
    userLogout(state) {
      state.user = {};
    },
    // 更新图标数组
    updateIcons(state, param) {
      state.iconList = param;
    },
    // 更新地址数组
    updateAddresses(state, param) {
      state.addressList = param;
    },
    // 更新基础标签数组
    updateBaseTags(state, param) {
      state.baseTagList = param;
    },
    // 更新标签数组
    updateTags(state, param) {
      state.tagList.list = param;
    },
    // 更新基础清单列表
    updateBaseProjects(state, param) {
      state.baseProjectList = param;
    },
    // 更新清单列表
    updateProjects(state, param) {
      setTimeout(() => {
        state.projectList = param;
      }, 0);
    },
    // 更新任务列表
    updateTasks(state, param) {
      state.taskList = param;
    },
    // 存储项目展开状态
    openChanged(state, param) {
      Object.assign(state.openFolder, param);
    },
    // 切换项目
    projectChanged(state, param) {
      state.currentProject = param;
      if (param.projectIdStr != state.currentTask.projectIdStr) {
        state.currentTask = {};
      }
    },
    // 切换任务
    taskChanged(state, param) {
      state.currentTask = param;
    },
    // 任务展开修改
    openTaskChanged(state, param) {
      Object.assign(state.openTask, param);
    },
    // 任务配置
    updateTaskDetailMode(state, mode) {
      state.taskDetailMode = mode;
    },
    updateTaskCompleteMode(state, mode) {
      state.taskCompleteMode = mode;
    },
    updateTaskSortId(state, sortId) {
      state.taskSortId = sortId;
    },
    updateListFlag(state, param) {
      state.listFlag = param;
    },
    updateMenuData(state, param) {
      state.menuData = param;
    },
    updateToastTask(state, task) {
      state.toastTask = task;
    },
    toastTaskUpdated(state, time) {
      state.toastTaskUpdatTime = time;
    },
    updateShowDialog(state, show) {
      state.showClockDialog = show;
    },
    updateClockInfo(state, info) {
      state.clockInfo = info;
    },
    updateFocusId(state, info) {
      state.focusId = info;
    },
    updateProjectCount(state, bool) {
      state.projectCount = bool;
    },
    updateProjectData(state, time) {
      state.projectTimestamp = time;
    },
  },
  actions: {
    // 异步方法
    // increase(context) {
    //   setTimeout(() => {
    //       // contex.dispatch可以用于触发其他action
    //     context.commit('increase');
    //   }, 1000);
    // }
  },
});

export default store;
