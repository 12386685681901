<template>
  <Modal
    class="search-modal"
    :mask="true"
    draggable
    sticky
    scrollable
    title="搜索任务"
    closable
    v-model="showModal"
    @on-ok="onModalOk"
  >
    <Input
      class="title"
      :class="ifocus ? 'ifocus' : ''"
      v-model="search"
      size="large"
      :border="false"
      placeholder="输入关键字搜索任务"
      clearable
      @on-change="debouncedHandleInput"
      prefix="ios-search"
      @on-blur="onTitleBlur"
      @on-focus="onTitleFocus"
    />
    <!-- <div v-if="tempList.length" style="height: 300px; overflow-y: scroll">
      <div
        style="display: flex; align-items: center"
        v-for="(item, index) in tempList"
        :key="item + index"
        @click="onTaskClick(item)"
      >
        <Icon
          v-if="onSelected"
          :type="selectedList.indexOf(item) > -1 ? 'ios-checkmark-circle' : 'md-radio-button-off'" 
          class="primary-color" 
          style="font-size: 18px;cursor: pointer;" 
          :type="
            selectedList.indexOf(item) > -1
              ? 'ios-checkmark-circle'
              : 'md-radio-button-off'
          "
          class="primary-color"
          style="font-size: 16px"
        />
        <XTaskNode :ignore="true" :data="item" style="margin: 0px; flex: 1" />
      </div>
    </div> -->
    <div class="list-fixed" v-show="tempList.length">
      <VirtList
        :buffer="2"
        :list="tempList"
        ref="virtListRef"
        itemKey="taskIdStr"
        :minSize="40"
        fixed
      >
        <template #default="{ itemData }">
          <div
            @click="onTaskClick(itemData)"
            style="display: flex; align-items: center"
          >
            <Icon
              v-if="onSelected"
              :type="
                selectedList.indexOf(itemData) > -1
                  ? 'ios-checkmark-circle'
                  : 'md-radio-button-off'
              "
              class="primary-color"
              style="font-size: 16px"
            />
            <XTaskNode
              :ignore="true"
              :data="itemData"
              style="margin: 0px; flex: 1"
            />
          </div>
        </template>
      </VirtList>
    </div>
    <div v-show="!tempList.length" style="text-align: center; height: 300px">
      <XIcon iconClass="no_task" style="font-size: 150px; margin-top: 40px" />
      <p style="font-size: 12px">没有找到你想要的</p>
    </div>
    <!-- 
    <Modal
      v-if="showTaskModal"
      :mask-closable="false"
      :scrollable="true"
      width="520px"
      :closable="false"
      v-model="showTaskModal"
    >
      <XToastTaskDetail :task="task" />
    </Modal> -->
    <Modal
      class="task-detail-modal"
      title=" "
      :mask="true"
      :width="520"
      draggable
      sticky
      scrollable
      v-model="showTaskModal"
      @on-visible-change="toastTaskChange"
    >
      <XToastTaskDetail :task="task" ref="toastTaskDetail" v-if="showTaskModal"/>
    </Modal>
  </Modal>
</template>

<script>
import XTaskNode from "@/components/XTaskNode";
import XToastTaskDetail from "@/components/XToastTaskDetail";
import { userInfo, taskList } from "@/common/api";
import { debounce } from "lodash";
import { VirtList } from "vue-virt-list";

export default {
  props: ["modelValue", "onSelected"],
  emits: ["update:modelValue"],
  components: {
    XTaskNode,
    XToastTaskDetail,
    VirtList,
  },
  provide() {
    return {
      onMenuClick: () => {},
    };
  },
  mounted() {
    this.loadData();
    this.debouncedHandleInput = debounce(this.onChange, 300); // 300ms 防抖延迟
    this.reactiveData = this.$refs.virtListRef.reactiveData;
  },
  data() {
    return {
      showModal: false,
      search: "",
      dataList: [],

      task: {},
      showTaskModal: false,
      selectedList: [],
      tempList: [],
      debouncedHandleInput: null,
      reactiveData: {
        renderBegin: 0,
        renderEnd: 0,
      },
    };
  },
  computed: {
    // realList() {
    //   let list = [];
    //   this.dataList.forEach((element) => {
    //     if (element?.title?.indexOf(this.search) > -1) {
    //       list.push(element);
    //     }
    //   });
    //   return list;
    // },
  },
  methods: {
    onTitleBlur() {
      this.ifocus = false;
    },
    onTitleFocus() {
      this.ifocus = true;
    },
    toastTaskChange(e) {
      if (e) {
        this.$refs?.toastTaskDetail?.initData();
      }
    },
    onChange() {
      this.tempList = this.dataList.filter((item) => {
        return item.title.includes(this.search);
      });
    },
    loadData() {
      userInfo().then((info) => {
        if (info && info.userId) {
          this.$store.commit("updateInfo", info);
          let projectId = "-2" + info.userId;
          taskList(projectId).then((res) => {
            if (res) {
              this.completeList = [];
              this.normalList = [];
              res.forEach((element) => {
                if (element.completeTime > 0 || element.giveUp) {
                  this.completeList.push(element);
                } else {
                  this.normalList.push(element);
                }
              });
              this.dataList = this.normalList.concat(this.completeList);
              this.tempList = this.normalList.concat(this.completeList);
            }
          });
        }
      });
    },
    onTaskClick(task) {
      if (this.onSelected) {
        if (this.selectedList.indexOf(task) > -1) {
          this.selectedList.splice(this.selectedList.indexOf(task), 1);
        } else {
          this.selectedList.push(task);
        }
      } else {
        console.log(111111111111);
        this.showTaskModal = true;
        this.task = task;
      }
    },
    onModalOk() {
      if (this.onSelected) {
        this.onSelected(this.selectedList);
      }
    },
  },
  watch: {
    modelValue() {
      this.showModal = this.modelValue;
    },
    showModal() {
      if (this.showModal) {
        this.search = "";
        this.selectedList = [];
        this.loadData();
      }
      this.$emit("update:modelValue", this.showModal);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  &.ifocus {
    :deep(.ivu-input) {
      border-color: #1b9aee;
      background-color: var(--vp-c-bg);
      box-shadow: 0px 1px 12px 0px #00000011;
    }
  }
  :deep(.ivu-input) {
    margin-bottom: 9px;
    border-radius: 10px;
    font-size: 14px;
    color: var(--vp-c-text-1);
    background-color: var(--vp-c-bg-alt);
    border: 1px dashed transparent;
    &:hover {
      border-color: #1b9aee;
    }
  }
}
.list-fixed {
  padding-top: 10px;
  width: 100%;
  height: 300px;
  // background-color: var(--vp-sidebar-bg-color);
  overflow: hidden;
  // border: 1px solid var(--vp-c-border);
  border-radius: 8px;
  padding: 15px;
  .task-node {
    padding: 0;
  }
}
</style>
